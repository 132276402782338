import * as React from 'react';
import '../../styles/global.scss';
import '../../styles/normalize.scss';
import '../../styles/typography.scss';
import '../../styles/settings.scss';
import RootLayout from '../../components/RootLayout/RootLayout';
import Headers from '../../components/Headers/Headers';
import Footer from '../../components/Footer/Footer';
import ScroolUpButton from '../../components/ScroolUpButton/ScroolUpButton';
import BlogArticleResponsiveDesign from '../../components/BlogArticles/BlogArticleResponsiveDesign';

const BlogResponsiveSite = () => {
  const title_top = 'What Is';
  const title_middle = 'Website';
  const title_bottom = 'Responsiveness?';
  const siteTitle = 'What Is Website Responsiveness and Why Is It So Important? - Nowoczesna witryna';
  const siteDescription =
    'Website responsiveness is its ability to adapt to various screen sizes – from desktops to smartphones and tablets. In today’s world, where most users browse on mobile devices, responsiveness has become a key aspect of modern websites.';
  const siteUrl = 'https://www.nowoczesnawitryna.com/blog/what-is-website-responsiveness/';
  const siteUrlOtherLang = 'https://www.nowoczesnawitryna.pl/blog/czym-jest-responsywnosc-strony/';

  return (
    <>
      <RootLayout title={siteTitle} url={siteUrl} urlOtherLang={siteUrlOtherLang} description={siteDescription} />
      <Headers title_top={title_top} title_middle={title_middle} title_bottom={title_bottom} active="blog" />
      <ScroolUpButton />
      <BlogArticleResponsiveDesign />
      <Footer />
    </>
  );
};

export default BlogResponsiveSite;
